<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card class="cardClassDetailInfo" :noHeader="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-label-text title="사업장" :value="plantName"></c-label-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-label-text title="자재명" :value="materialName"></c-label-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-label-text title="자재코드" :value="materialCd"></c-label-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-label-text title="화학자재일련번호" :value="param.mdmChemMaterialId"></c-label-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-12 col-lg-12">
          <c-card title="PSM 정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <q-btn
                  icon="help"
                  color="deep-purple-6"
                  text-color="white"
                  class="custom-btn"
                  align="center"
                  round>
                  <q-tooltip anchor="bottom left" self="top left">
                    <div class="tooltipCustomTop">
                      (주)
                    </div>
                    <div class="tooltipCustom">
                    ① 유해․위험물질은 제출대상 설비에서 제조 또는 취급하는 모든 화학물질을 기재합니다.<br>
                    ② 증기압은 상온에서의 증기압을 말합니다.<br>
                    ③ 부식성 유무는 있으면 ○, 없으면 ×로 표시합니다.<br>
                    ④ 이상반응 여부는 그 물질과 이상반응을 일으키는 물질과 그 조건(금수성 등)을 표시하고 필요시 별도로 작성합니다.<br>
                    ⑤ 노출기준에는 시간가중평균노출기준(TWA)을 기재합니다.<br>
                    ⑥ 독성치에는 LD50(경구, 쥐), LD50(경피, 쥐 또는 토끼) 또는 LC50(흡입, 4시간 쥐)을 기재합니다.
                    </div>
                  </q-tooltip>
                </q-btn>
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveHazard"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  suffix="%"
                  :editable="editable"
                  label="폭발한계상한(%)"
                  name="explUpLimit"
                  v-model="data.explUpLimit">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  suffix="%"
                  :editable="editable"
                  label="폭발한계하한(%)"
                  name="explLowLimit"
                  v-model="data.explLowLimit">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  suffix="℃"
                  :editable="editable"
                  label="인화점(℃)"
                  name="flashingPoint"
                  v-model="data.flashingPoint">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  suffix="℃"
                  :editable="editable"
                  label="발화점(℃)"
                  name="ignitionPoint"
                  v-model="data.ignitionPoint">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :counter="true"
                  :maxlength="500"
                  :editable="editable"
                  :rows="3"
                  label="노출기준"
                  name="exposureLimit"
                  v-model="data.exposureLimit">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :counter="true"
                  :maxlength="500"
                  :editable="editable"
                  :rows="3"
                  label="독성치"
                  name="toxicValue"
                  v-model="data.toxicValue">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  suffix="mmHg"
                  :editable="editable"
                  label="증기압(20℃,mmHg)"
                  name="vaporPressure"
                  v-model="data.vaporPressure">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  suffix="Nm/kg"
                  :editable="editable"
                  label="일일취급량(kg)"
                  name="dailyUsage"
                  v-model="data.dailyUsage">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  suffix="Nm/kg"
                  :editable="editable"
                  label="저장량(kg)"
                  name="stockage"
                  v-model="data.stockage">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-dept type="edit" label="관리부서" name="deptCd" v-model="data.deptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :counter="true"
                  :maxlength="500"
                  :editable="editable"
                  :rows="3"
                  label="이상반응유무"
                  name="extraReaction"
                  v-model="data.extraReaction">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :counter="true"
                  :maxlength="500"
                  :editable="editable"
                  :rows="3"
                  label="부식성유무"
                  name="corrosivenessFlag"
                  v-model="data.corrosivenessFlag">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chem-psm',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        plantCd: '',
        plantName: '',
        materialCd: '',
        materialName: '',
        isSaved: '',
      }),
    },
  },
  data() {
    return {
      plantCd: '',
      plantName: '',
      materialCd: '',
      materialName: '',
      saveUrl: '',
      detailUrl: '',
      mappingType: 'POST',
      editable: true,
      isSave: false,
      updateMode: false,
      data: {
        psiHazardMaterialId: '',  // 유해위험물질 일련번호
        mdmChemMaterialId: '',  // 화학자재 일련번호
        plantCd: this.param.plantCd,  // 사업장코드
        deptCd: '',  // 관리부서
        explUpLimit: '',  // 폭발한계상한(vol%)
        explLowLimit: '',  // 폭발한계하한(vol%)
        flashingPoint: '',  // 인하점(℃)
        ignitionPoint: '',  // 발화점(℃)
        vaporPressure: '',  // 증기압(20℃)
        extraReaction: '',  // 이상반응유무(내용기술)
        corrosivenessFlag: '',  // 부식성유무
        dailyUsage: '',  // 일 사용량
        stockage: '',  // 저장량
        remarks: '',  // 비고
        exposureLimit: '',  // 노출기준TWA
        toxicValue: '',  // 독성치
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.mam.chem.hazard.url;
      this.saveUrl = transactionConfig.mdm.mam.chem.hazard.insert.url;
      this.infoUrl = selectConfig.mdm.mam.chem.get.url;
      this.getDetail();
    },
    getDetail() {
      if (this.param.mdmChemMaterialId) {
        this.$http.url = this.$format(this.infoUrl, this.param.mdmChemMaterialId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.plantCd = _result.data.plantCd;
          this.plantName = _result.data.plantName;
          this.materialCd = _result.data.materialCd;
          this.materialName = _result.data.materialName;
        },);

        this.$http.url = this.detailUrl;
        this.$http.param = {mdmChemMaterialId: this.param.mdmChemMaterialId, plantCd: this.param.plantCd};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (_result.data.psiHazardMaterialId) {
            this.data = _result.data;
          } else {
            this.data = {
              psiHazardMaterialId: '',  // 유해위험물질 일련번호
              mdmChemMaterialId: this.param.mdmChemMaterialId,  // 화학자재 일련번호
              plantCd: this.param.plantCd,  // 사업장코드
              deptCd: '',  // 관리부서
              explUpLimit: '',  // 폭발한계상한(vol%)
              explLowLimit: '',  // 폭발한계하한(vol%)
              flashingPoint: '',  // 인하점(℃)
              ignitionPoint: '',  // 발화점(℃)
              vaporPressure: '',  // 증기압(20℃)
              extraReaction: '',  // 이상반응유무(내용기술)
              corrosivenessFlag: '',  // 부식성유무
              dailyUsage: '',  // 일 사용량
              stockage: '',  // 저장량
              remarks: '',  // 비고
              exposureLimit: '',  // 노출기준TWA
              toxicValue: '',  // 독성치
            }
          }
        },);
      }
    },
    saveHazard() {
      if (this.data.psiHazardMaterialId) {
        this.mappingType = 'PUT';
      } else {
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
  }
};
</script>